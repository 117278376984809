const loadingOverlay = {
  show: function () {
    const overlay = document.createElement("div");
    overlay.classList.add("loading-overlay");
    const spinner = document.createElement("div");
    spinner.classList.add("spinner-border");
    spinner.setAttribute("role", "status");
    overlay.appendChild(spinner);
    document.body.appendChild(overlay);
  },
  hide: function () {
    const overlay = document.querySelector(".loading-overlay");
    if (overlay) {
      overlay.remove();
    }
  },
};

const toggleGameInfo = (id) => {
  var gameItem = document.getElementById(id);
  // var elGameInfo = gameItem.querySelector(".game-info");
  // elGameInfo.classList.toggle("game-info--open");
  gameItem.classList.toggle("game-info--open");
  gameItem.scrollIntoView({
    behavior: 'smooth'
  });
};

const goPlay = (url) => {
  window.location.assign(url);
  loadingOverlay.show();
};
